<template>
  <section class="emotion-parameter-wrap" v-loading="loading">
    <div class="item">当文章评分范围为 0分～<el-input type="number" v-model="negative" @change="changeRange"/>分时为负面</div>
    <div class="item">当文章评分范围为 {{ negative }}分～<el-input type="number" v-model="sensitive"  @change="changeRange"/>分时为敏感</div>
    <div class="item">当文章评分范围为 {{ sensitive }}分～{{ positive }}分时为正面</div>
    <el-button type="primary" size="small" @click="updateRange">保存</el-button>
  </section>
</template>

<script>
import { listEmotionRange, updateEmotionRange } from '@/api/settings/emotion'
import eventHub from '@/utils/eventHub'
export default {
  data () {
    return ({
      loading: true,
      negative: '60',
      sensitive: '70',
      positive: '100',
      oldnegative: '60',
      oldsensitive: '70',
      oldpositive: '100'
    })
  },
  mounted () {
    this.showRange()
    eventHub.$emit('msg', '情感参数')
  },
  computed: {
    // 监听project数据
    getProject () {
      return this.$store.state.app.project
    }
  },
  watch: {
    //  监听改变事件
    getProject: {
      handler (newValue, oldValue) {
        this.showRange()
      }
    }
  },
  methods: {
    changeRange () {
      if (this.negative <= 0 || this.sensitive <= this.negative) {
        console.log(this.negative + '' + this.sensitive + '' + this.positive)
        this.$message.error('评分范围非法，请检查')
        this.sensitive = this.oldsensitive
        this.positive = this.oldpositive
        this.negative = this.oldnegative
      } else {
        this.oldsensitive = this.sensitive
        this.oldpositive = this.positive
        this.oldnegative = this.negative
      }
    },
    async showRange () {
      const res = await listEmotionRange()
      this.negative = res.data.negativeRange
      this.oldnegative = this.negative
      this.sensitive = res.data.sensitiveRange
      this.oldsensitive = this.sensitive
      this.positive = res.data.positiveRange
      this.oldpositive = this.positive
      console.log(res)
      this.loading = false
    },
    async updateRange () {
      this.loading = true
      const res = await updateEmotionRange({ negativeRange: this.negative, sensitiveRange: this.sensitive, positiveRange: this.positive })
      if (res.code === 0) {
        this.$message.success('修改成功')
        this.showRange()
      } else {
        this.$message.error('修改失败')
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.emotion-parameter-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 2px;
  padding: 20px 28px;
  // box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  .item {
    // margin-bottom: 24px;
    border-bottom: 1px solid #eaeaea;
    padding: 15px 0;
    .el-input {
      width: 65px;
      height: 20px;
      margin: 0 4px;
      .el-input__inner {
        height: 20px;
        line-height: 20px;
        text-align: center;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button{
          -webkit-appearance: none !important;
      }
      input[type="number"]{
          -moz-appearance:textfield;
      }
    }

  }
  .el-button {
    margin-top: 24px;
  }
}
</style>
