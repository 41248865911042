import request from '@/utils/request'
// 获取情感判定分数范围
export function listEmotionRange () {
  return request({
    url: '/emotion/showRange',
    method: 'POST'
  })
}
export function updateEmotionRange (data) {
  return request({
    url: '/emotion/updateRange',
    method: 'POST',
    data
  })
}
